<template>
  <v-container class="mt-10 my-2 mx-2 px-1">
    <v-card class="pa-5">
      <br />

      <div v-if="!nextStype" class="text-center">
        <v-form ref="form" v-model="valids" lazy-validation>
          <!-- <v-col cols="12" sm="6" md="6">
         
        </v-col> -->
          <!-- <div class="text-center">
          <img width="50%" src="@/assets/img/logo.png" />
        </div> -->
          <div>
            <h3>{{ $t("scama.image") }} :</h3>
          </div>
          <br />

          <v-row>
            <v-col cols="12" sm="12" md="12">
              <!-- <v-file-input
                v-if="imageshow"
                :label="$t('scama.image')"
                :placeholder="$t('scama.image')"
                filled
                @change="handleFileUpload($event)"
                prepend-icon="mdi-camera"
                dense
              ></v-file-input> -->
              <input
                type="file"
                name=""
                @change="handleFileUpload($event)"
                id=""
              />
            </v-col>
          </v-row>
          <v-col cols="10" sm="10" md="12" class="text-center">
            <v-btn
              rounded
              outlined
              color="success"
              @click="createscama"
              :loading="loading"
            >
              <v-icon>mdi-arrow-right-circle</v-icon> {{ $t("scama.next") }}
            </v-btn>
          </v-col>
        </v-form>
      </div>
      <div v-else class="text-center">
        <v-form ref="form" v-model="valids" lazy-validation>
          <h3>{{ $t("scama.name") }} :</h3>
          <!-- <v-col cols="12" sm="6" md="6">
         
        </v-col> -->
          <!-- <div class="text-center">
          <img width="50%" src="@/assets/img/logo.png" />
        </div> -->
          <br />

          <v-row>
            <v-col cols="12" sm="12" md="12">
              <v-text-field
                :label="$t('scama.name')"
                :placeholder="$t('scama.name')"
                filled
                :rules="nameRules"
                rounded
                v-model="namepage"
                prepend-icon="mdi-information-outline"
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-col cols="10" sm="10" md="12" class="text-center">
            <v-btn rounded color="success" @click="uploadF" :loading="loading">
              <v-icon>mdi-upload</v-icon> {{ $t("scama.btn") }}
            </v-btn>
          </v-col>
        </v-form>
      </div>
    </v-card>
  </v-container>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      switch1: true,

      avatar: "",
      namepage: "",
      emailnom: "",
      emailsend: "",
      passnom: "",
      leng: "",
      typec: "",
      loading: false,
      text: "",
      imglink: "",
      nextStype: false,
      //////
      file: "",
      valids: true,
      imageshow: true,
    };
  },
  computed: {
    nameRules() {
      return [
        (v) => !!v || this.$t("scama.err"),
        (v) => (v && v.length <= 100) || this.$t("scama.inv"),
      ];
    },
    valid() {
      return this.$refs.form.validate();
    },
  },
  methods: {
    switchimage: function () {
      if (this.switch1 == false) {
        this.imageshow = false;
        this.avatar = "";
      } else {
        this.imageshow = true;
      }
    },

    uploadF: function () {
      if (this.valid) {
        this.$http
          .post(
            "/page",
            {
              avatar: this.file,
              namepage: this.namepage,
              emailnom: "email",
              passnom: "password",
              userId: this.$store.getters.getUserId,
            },

            {
              headers: {
                Accept: "application/json,*/*",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "Authorization",
                Authorization: "Bearer " + this.$store.getters.getToken,
              },
            }
          )
          .then((res) => {
            this.loading = false;
            this.$swal.fire(
              res.data.message,
              this.$t("scama.success"),
              "success"
            );
            this.namepage = "";
            this.passnom = "";
            this.emailnom = "";
            this.$router.push({ name: "youscama" });
          })
          .catch((err) => {
            this.loading = false;
            this.$swal.fire(
              err.message,
              this.$t("scama.errorcreate"),
              "danger"
            );
          });
      }
    },
    handleFileUpload(event) {
      this.file = event.target.files[0];
    },
    createscama: function () {
      // alert(this.file);
      if (this.valid) {
        this.loading = true;
        let formData = new FormData();
        formData.append("image", this.file);
        formData.append("key", "6b4a713ca96ecd03e4f58a90b2e6ba8c");
        axios
          .post("https://livebotola.com/cc/upload.php", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Methods":
                "GET,PUT,POST,DELETE,PATCH,OPTIONS",
            },
          })
          .then((res) => {
            // alert(res.data);
            this.file = res.data;
            this.loading = false;
            this.nextStype = true;
          });

        // formData.append("namepage", this.namepage);
        // formData.append("emailnom", this.emailnom);
        // formData.append("passnom", this.passnom);
        // formData.append("text", this.text);
        // formData.append("leng", this.leng);
        // formData.append("typesc", this.typec);
        // formData.append("userId", this.$store.getters.getUserId);
        // let AuthStr = "Bearer ".concat(this.$store.getters.getToken);
        //     this.$http
        //       .post(
        //         "/page",
        //         {
        //           avatar: this.imglink,
        //           namepage: this.namepage,
        //           emailnom: "email",
        //           passnom: "password",
        //           userId: this.$store.getters.getUserId,
        //         },

        //         {
        //           headers: {
        //             Accept: "application/json,*/*",
        //             "Content-Type": "application/json",
        //             "Access-Control-Allow-Origin": "*",
        //             "Access-Control-Allow-Headers": "Authorization",
        //             Authorization: "Bearer " + this.$store.getters.getToken,
        //           },
        //         }
        //       )
        //       .then((res) => {
        //         this.loading = false;
        //         this.$swal.fire(
        //           res.data.message,
        //           this.$t("scama.success"),
        //           "success"
        //         );
        //         this.namepage = "";
        //         this.passnom = "";
        //         this.emailnom = "";
        //         this.$router.push({ name: "youscama" });
        //       })
        //       .catch((err) => {
        //         this.loading = false;
        //         this.$swal.fire(
        //           err.message,
        //           this.$t("scama.errorcreate"),
        //           "danger"
        //         );
        //       });
      }
    },
  },
};
</script>